import Cookies from 'js-cookie'

export const GTM_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID

declare global {
  interface Window {
    dataLayer: any
    cj: any

    // app传过来的token
    h5Token?: string

    DATAFLUX_RUM: any
  }
}

export type LineItems = Array<LineItem>
export type ProductItems = Array<ProductItem>

/**
 * 购物车数据
 */
export interface LineItem {
  item_id: string
  item_name: string
  coupon?: string
  discount?: number
  affiliation: string
  item_brand?: string
  item_category?: string
  item_variant?: string
  price: number
  currency: string
  quantity: number

  /** UA */
  id?: string
  name?: string
  brand?: string
  category?: string
  variant?: string
  position?: number
}

/**
 * 产品
 */
export interface ProductItem {
  /** GA */
  item_id: string
  item_name: string
  coupon?: string
  discount?: number
  affiliation: string
  item_brand?: string
  item_category?: string
  item_variant?: string
  item_list_name?: string
  item_list_id?: string
  price: number
  currency: string
  index?: number
  quantity?: number

  /** UA */
  id?: string
  name?: string
  brand?: string
  category?: string
  variant?: string
  position?: number
  list?: string
}

export type ContentIds = Array<string>

export type Contents = Array<ContentItem>

/**
 * 商品内容
 */
export interface ContentItem {
  id: string
  quantity: number
}

const enterpriseId = process.env.NEXT_PUBLIC_CJ_ENTERPRISE_ID

export function setSitePage(pageData: Record<string, unknown>) {
  if (enterpriseId) {
    const userId = Cookies.get('user_id')
    if (!window.cj) window.cj = {}
    window.cj.sitePage = {
      enterpriseId,
      userId,
      ...pageData,
    }
  }
}

/**
 * Google pageView
 */
export const pageView = (url: string) => {
  const user_id = Cookies.get('user_id')
  window.dataLayer?.push({
    event: 'pageview',
    page: url,
    user_id,
    hs_id: user_id,
  })
}

export const customEvent = (event: string, params: any = {}) => {
  const user_id = Cookies.get('user_id')

  window.dataLayer?.push({
    event,
    user_id,
    hs_id: user_id,
    ...params,
  })
}

/**
 * 添加商品入购物车
 */
export const addToCart = (data: {
  value: number
  /* 购物车列表 */
  items: Array<LineItem>
  content_name: string
  content_category: string
  content_ids: ContentIds
  contents: Contents
}) => {
  const user_id = Cookies.get('user_id')

  window.dataLayer?.push({
    event: 'add_to_cart',
    currency: 'USD',
    user_id,
    hs_id: user_id,
    ...data,
  })
}

export const adsAddToCart = () =>
  window.dataLayer?.push([
    'event',
    'conversion',
    {
      send_to: `${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID}/KLraCO6ig6YDEKXKsK4o`,
    },
  ])

/**
 * 查看购物车
 */
export const viewCart = (data: {
  value: number
  items: Array<LineItem>
  content_ids: ContentIds
  contents: Contents
}) => {
  const user_id = Cookies.get('user_id')

  window.dataLayer?.push({
    event: 'view_cart',
    currency: 'USD',
    user_id,
    hs_id: user_id,
    ...data,
  })
}

/**
 * 付款成功
 */
export const purchase = (data: {
  /* 金额 */
  value: number
  /* 税费 */
  tax: number
  /* 交易的唯一标识 */
  transaction_id?: string
  /* 购物车列表 */
  items: Array<LineItem>
  content_ids: ContentIds
  contents: Contents
  num_items: number
  content_name: string
}) => {
  const user_id = Cookies.get('user_id')
  window.dataLayer?.push({
    event: 'purchase',
    currency: 'USD',
    user_id,
    hs_id: user_id,
    ...data,
  })
}

/**
 * 移除购物车
 * @param data
 */
export const removeFromCart = (data: {
  value: number
  items: Array<LineItem>
  content_ids: ContentIds
  contents: Contents
}) => {
  const user_id = Cookies.get('user_id')
  window.dataLayer?.push({
    event: 'remove_from_cart',
    currency: 'USD',
    user_id,
    hs_id: user_id,
    ...data,
  })
}

/**
 * 开始结账
 * @param data
 */
export const beginCheckout = (data: {
  value: number
  items: Array<LineItem>
  content_ids: ContentIds
  contents: Contents
  num_items: number
}) => {
  const user_id = Cookies.get('user_id')

  window.dataLayer?.push({
    event: 'begin_checkout',
    currency: 'USD',
    user_id,
    hs_id: user_id,
    ...data,
  })
}

/**
 * 浏览商品列表
 * @param data
 */
export const viewItemList = (data: {
  item_list_id: string
  item_list_name: string
  items: Array<ProductItem>
  content_ids: ContentIds
  contents: Contents
}) => {
  const user_id = Cookies.get('user_id')
  window.dataLayer?.push({
    event: 'view_item_list',
    currency: 'USD',
    user_id,
    hs_id: user_id,
    ...data,
  })
}

/**
 * 浏览商品
 * @param data
 */
export const viewItem = (data: {
  value: number
  items: Array<ProductItem>
  content_ids: ContentIds
  contents: Contents
  content_name: string
  content_category: string
}) => {
  const user_id = Cookies.get('user_id')
  window.dataLayer?.push({
    event: 'view_item',
    currency: 'USD',
    user_id,
    hs_id: user_id,
    ...data,
  })
}

export type AddToCartParams = {
  value: number
  /* 购物车列表 */
  items: Array<LineItem>
  content_name: string
  content_category: string
  content_ids: ContentIds
  contents: Contents
}

export type ViewCartParams = {
  value: number
  items: Array<LineItem>
  content_ids: ContentIds
  contents: Contents
}

export type PurchaseParams = {
  /* 金额 */
  value: number
  /* 税费 */
  tax: number
  /* 交易的唯一标识 */
  transaction_id?: string
  /* 购物车列表 */
  items: Array<LineItem>
  content_ids: ContentIds
  contents: Contents
  num_items: number
  content_name: string
}

export type RemoveFromCartParams = {
  value: number
  items: Array<LineItem>
  content_ids: ContentIds
  contents: Contents
}

export type BeginCheckoutParams = {
  value: number
  items: Array<LineItem>
  content_ids: ContentIds
  contents: Contents
  num_items: number
}

export type ViewItemParams = {
  value: number
  items: Array<ProductItem>
  content_ids: ContentIds
  contents: Contents
  content_name: string
  content_category: string
}

export type SelectItemParams = {
  items: Array<ProductItem>
  item_list_name?: string
}

export type ViewItemListParams = {
  item_list_id: string
  item_list_name: string
  items: Array<ProductItem>
  content_ids: ContentIds
  contents: Contents
}

export class GoogleTagManager {
  /**
   * GTM上报
   * @param params 数据层参数
   */
  static dataLayerPush(params: any) {
    window.dataLayer?.push(params)
  }

  static customEvent(event: string, params?: any) {
    const user_id = this.getUserId()
    this.dataLayerPush({
      event,
      user_id,
      hs_id: user_id,
      ...params,
    })
  }

  /**
   * 清空ecommerce对象
   */
  static clearEcommerce() {
    this.dataLayerPush({ ecommerce: null }) // Clear the previous ecommerce object.
  }

  /**
   * 获取用户ID
   * @returns user_id
   */
  static getUserId() {
    return Cookies.get('user_id')
  }

  /**
   * 上报add_to_cart事件
   * @param params google电子商务添加购物车数据
   */
  static addToCart(params: AddToCartParams) {
    const user_id = this.getUserId()

    this.dataLayerPush({
      event: 'add_to_cart',
      currency: 'USD',
      user_id,
      hs_id: user_id,
      ecommerce: {
        ...params,

        // UA 数据
        currencyCode: 'USD',
        add: {
          products: params?.items,
        },
      },
    })
  }

  /**
   * 上报ads add_to_cart事件
   */
  // static adsAddToCart() {
  //   this.dataLayerPush([
  //     'event',
  //     'conversion',
  //     {
  //       send_to: `${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID}/KLraCO6ig6YDEKXKsK4o`,
  //     },
  //   ])
  // }

  /**
   * 上报view_cart事件
   * @param params google电子商务view_cart数据
   */
  static viewCart(params: ViewCartParams) {
    const user_id = this.getUserId()
    this.clearEcommerce()
    this.dataLayerPush({
      event: 'view_cart',
      currency: 'USD',
      user_id,
      hs_id: user_id,
      ecommerce: params,
    })
  }

  /**
   * 上报purchase事件
   * @param params google电子商务purchase数据
   */
  static purchase(params: BeginCheckoutParams) {
    const user_id = this.getUserId()
    this.clearEcommerce()

    this.dataLayerPush({
      event: 'purchase',
      currency: 'USD',
      user_id,
      hs_id: user_id,
      ecommerce: {
        ...params,

        currencyCode: 'USD',
        add: {
          products: params?.items,
        },
      },
    })
  }

  /**
   * 上报remove_from_cart事件
   * @param params google电子商务remove_from_cart数据
   */
  static removeFromCart(params: RemoveFromCartParams) {
    const user_id = this.getUserId()

    this.clearEcommerce()
    this.dataLayerPush({
      event: 'remove_from_cart',
      currency: 'USD',
      user_id,
      hs_id: user_id,
      ecommerce: {
        ...params,

        // UA 数据
        currencyCode: 'USD',
        remove: {
          products: params?.items,
        },
      },
    })
  }

  /**
   * begin_checkout
   * @param params google电子商务开始结算数据
   */
  static beginCheckout(params: BeginCheckoutParams) {
    const user_id = this.getUserId()

    this.clearEcommerce()
    this.dataLayerPush({
      event: 'begin_checkout',
      currency: 'USD',
      user_id,
      hs_id: user_id,
      ecommerce: {
        ...params,

        // UA 数据
        currencyCode: 'USD',
        products: params.items,
      },
    })
  }

  /**
   * UA begin_checkout
   * @param params google电子商务开始结算数据
   */
  static beginCheckout_ua(params: BeginCheckoutParams) {
    const user_id = this.getUserId()

    /** UA */
    this.clearEcommerce()
    this.dataLayerPush({
      event: 'ua_begin_checkout',
      user_id,
      hs_id: user_id,
      ecommerce: {
        currencyCode: 'USD',
        checkout: {
          actionField: { step: 1, option: 'Checkout Now/Buy Now' },
          products: params.items,
        },
      },
    })
  }

  /**
   * onCheckoutOption
   * @param step 结账的步骤
   * @param checkoutOption 额外数据
   */
  static onCheckoutOption(step: number, checkoutOption?: string) {
    const user_id = this.getUserId()
    /** UA */
    this.clearEcommerce()
    this.dataLayerPush({
      user_id,
      hs_id: user_id,
      ecommerce: {
        currencyCode: 'USD',
        checkout_option: {
          actionField: { step, option: checkoutOption },
        },
      },
    })
  }

  /**
   * 上报view_item事件
   * @param params google电子商务浏览商品数据
   */
  static viewItem(params: ViewItemParams) {
    const user_id = this.getUserId()

    this.clearEcommerce()
    this.dataLayerPush({
      event: 'view_item',
      currency: 'USD',
      user_id,
      hs_id: user_id,
      ecommerce: {
        ...params,

        //UA 数据
        currencyCode: 'USD',
        detail: {
          actionField: { list: params?.content_category },
          products: params?.items,
        },
      },
    })
  }

  /**
   * 上报select_item事件
   * @param params google电子商务select_item数据
   */
  static selectItem(params: SelectItemParams) {
    const user_id = this.getUserId()

    this.clearEcommerce()
    this.dataLayerPush({
      event: 'select_item',
      currency: 'USD',
      user_id,
      hs_id: user_id,
      ecommerce: {
        ...params,

        // UA 数据
        currencyCode: 'USD',
        click: {
          actionField: { list: params.item_list_name },
          products: params?.items,
        },
      },
    })
  }

  /**
   * 上报view_item_list事件
   * @param params google电子商务浏览商品列表数据
   */
  static viewItemList(params: ViewItemListParams) {
    const user_id = this.getUserId()

    this.clearEcommerce()
    this.dataLayerPush({
      event: 'view_item_list',
      currency: 'USD',
      user_id,
      hs_id: user_id,
      ecommerce: {
        ...params,

        // UA 数据
        currencyCode: 'USD',
        impressions: params?.items,
      },
    })
  }
}

export default GoogleTagManager
