import { createTheme, responsiveFontSizes, Theme } from '@mui/material/styles'
import {
  bold,
  MuiTypographyVariantMapping,
  regular,
  semiBold,
} from './typography'
import colorThemeOptions, { primary } from './color'
import { breakpoints, breakpointsValues } from './breakpoints'
import MuiButton from './components/button'
// import { Manrope } from '@next/font/google'
// const manrope = Manrope({ subsets: ['latin'] })

// import UrbanistBoldTtf from './fonts/Urbanist/Urbanist-Bold.ttf'
// import UrbanistMediumTtf from './fonts/Urbanist/Urbanist-Medium.ttf'
// import UrbanistRegularWoff2 from 'constants/fonts/Urbanist/Urbanist-Regular.woff2'

// 标题基础样式
const h_styles = {
  h2: {
    fontSize: 32,
    lineHeight: 1.354,
    [breakpoints.up('lg')]: {
      fontSize: 36,
    },
    [breakpoints.up('xl')]: {
      fontSize: 48,
    },
  },
  h3: {
    fontSize: 24,
    lineHeight: 1.25,
    [breakpoints.up('md')]: {
      fontSize: 28,
    },
    [breakpoints.up('lg')]: {
      fontSize: 30,
    },
    [breakpoints.up('xl')]: {
      fontSize: 30,
    },
  },
  headLarge: {
    fontSize: 32,
    lineHeight: 1.366,
    letterSpacing: '-0.029em',

    [breakpoints.up('md')]: {
      fontSize: 46,
    },
    [breakpoints.up('lg')]: {
      fontSize: 60,
    },
    [breakpoints.up('xl')]: {
      fontSize: 90,
    },
  },
  head1: {
    fontSize: 24,
    lineHeight: 1.354,
    letterSpacing: '-0.029em',

    [breakpoints.up('md')]: {
      fontSize: 32,
    },
    [breakpoints.up('lg')]: {
      fontSize: 36,
    },
    [breakpoints.up('xl')]: {
      fontSize: 48,
    },
  },
  head2: {
    fontSize: 36,
    lineHeight: 1.349,
    letterSpacing: '-0.029em',

    [breakpoints.up('lg')]: {
      fontSize: 28,
    },
    [breakpoints.up('xl')]: {
      fontSize: 42,
    },
  },
  head3: {
    fontSize: 32,
    lineHeight: 1.36,
    letterSpacing: '-0.029em',

    [breakpoints.up('lg')]: {
      fontSize: 26,
    },
    [breakpoints.up('xl')]: {
      fontSize: 36,
    },
  },
  head4: {
    fontSize: 24,
    lineHeight: 1.375,
    letterSpacing: '-0.029em',

    [breakpoints.up('xl')]: {
      fontSize: 32,
    },
  },
  head5: {
    fontSize: 20,
    lineHeight: 1.375,
    letterSpacing: '-0.029em',

    [breakpoints.up('xl')]: {
      fontSize: 24,
    },
  },

  body1: {
    fontSize: 20,
    lineHeight: 1.375,
    letterSpacing: '-0.02em',

    [breakpoints.up('md')]: {
      fontSize: 24,
    },
  },
  body2: {
    fontSize: 16,
    lineHeight: 1.35,
    letterSpacing: '-0.001em',

    [breakpoints.up('md')]: {
      fontSize: 20,
    },
  },
  body3: {
    fontSize: 16,
    lineHeight: 1.33,
    letterSpacing: '-0.001em',

    [breakpoints.up('md')]: {
      fontSize: 18,
    },
  },
  body4: {
    fontSize: 16,
    lineHeight: 1.35,
    letterSpacing: '-0.001em',
  },
  label1: {
    fontSize: 14,
    lineHeight: 1.356,
    letterSpacing: '-0.001em',
  },
  label2: {
    fontSize: 12,
    lineHeight: 1.4,
    letterSpacing: '-0.001em',
  },
}

const theme: Theme = createTheme({
  breakpoints: {
    values: breakpointsValues,
  },
  ...colorThemeOptions,

  typography: {
    fontSize: 14,

    ...bold,
    ...semiBold,
    ...regular,

    // 标题
    headLargeBold: {
      ...h_styles.headLarge,
      fontWeight: 700,
    },
    headLargeMedium: {
      ...h_styles.headLarge,
      fontWeight: 500,
    },
    headLargeRegular: {
      ...h_styles.headLarge,
      fontWeight: 400,
    },
    head1Bold: {
      ...h_styles.head1,
      fontWeight: 700,
    },
    head1Medium: {
      ...h_styles.head1,
      fontWeight: 500,
    },
    head1Regular: {
      ...h_styles.head1,
      fontWeight: 400,
    },
    head2Bold: {
      ...h_styles.head2,
      fontWeight: 700,
    },
    head2Medium: {
      ...h_styles.head2,
      fontWeight: 500,
    },
    head2Regular: {
      ...h_styles.head2,
      fontWeight: 400,
    },
    head3Bold: {
      ...h_styles.head3,
      fontWeight: 700,
    },
    head3Medium: {
      ...h_styles.head3,
      fontWeight: 500,
    },
    head3Regular: {
      ...h_styles.head3,
      fontWeight: 400,
    },
    head4Bold: {
      ...h_styles.head4,
      fontWeight: 700,
    },
    head4Medium: {
      ...h_styles.head4,
      fontWeight: 500,
    },
    head4Regular: {
      ...h_styles.head4,
      fontWeight: 400,
    },
    head5Bold: {
      ...h_styles.head5,
      fontWeight: 700,
    },
    head5Medium: {
      ...h_styles.head5,
      fontWeight: 500,
    },
    head5Regular: {
      ...h_styles.head5,
      fontWeight: 400,
    },

    h2_700: {
      ...h_styles.h2,
      fontWeight: 700,
    },

    h3_700: {
      ...h_styles.h3,
      fontWeight: 700,
    },

    body1Bold: {
      ...h_styles.body1,
      fontWeight: 700,
    },
    body1Medium: {
      ...h_styles.body1,
      fontWeight: 500,
    },
    body1Regular: {
      ...h_styles.body1,
      fontWeight: 400,
    },
    body2Bold: {
      ...h_styles.body2,
      fontWeight: 700,
    },
    body2Medium: {
      ...h_styles.body2,
      fontWeight: 500,
    },
    body2Regular: {
      ...h_styles.body2,
      fontWeight: 400,
    },
    body3Bold: {
      ...h_styles.body3,
      fontWeight: 700,
    },
    body3Medium: {
      ...h_styles.body3,
      fontWeight: 500,
    },
    body3Regular: {
      ...h_styles.body3,
      fontWeight: 400,
    },
    body4Bold: {
      ...h_styles.body4,
      fontWeight: 700,
    },
    body4Medium: {
      ...h_styles.body4,
      fontWeight: 500,
    },
    body4Regular: {
      ...h_styles.body4,
      fontWeight: 400,
    },
    label1Bold: {
      ...h_styles.label1,
      fontWeight: 700,
    },
    label1Medium: {
      ...h_styles.label1,
      fontWeight: 500,
    },
    label1Regular: {
      ...h_styles.label1,
      fontWeight: 400,
    },
    label2Bold: {
      ...h_styles.label2,
      fontWeight: 700,
    },
    label2Medium: {
      ...h_styles.label2,
      fontWeight: 500,
    },
    label2Regular: {
      ...h_styles.label2,
      fontWeight: 400,
    },

    body3_400: {
      fontSize: 14,
      lineHeight: 1.25,
    },

    body4_400: {
      fontSize: 16,
      lineHeight: 1.25,
    },
    body4_600: {
      fontSize: 16,
      lineHeight: 1.25,
      fontWeight: 700,
    },
    body4_700: {
      fontSize: 16,
      lineHeight: 1.25,
      fontWeight: 700,
    },

    body5_700: {
      fontSize: 18,
      lineHeight: 1.25,
      fontWeight: 700,
    },

    button2_700: {
      fontSize: 14,
      lineHeight: 1.25,
      fontWeight: 700,
    },

    // fontFamily: [manrope.style.fontFamily, 'sans-serif'].join(','),
    fontFamily: ['Manrope', 'sans-serif'].join(','),
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          ...MuiTypographyVariantMapping,
          headLargeBold: 'p',
          headLargeMedium: 'p',
          headLargeRegular: 'p',
          head1Bold: 'h1',
          head1Medium: 'h1',
          head1Regular: 'h1',
          head2Bold: 'h2',
          head2Medium: 'h2',
          head2Regular: 'h2',
          head3Bold: 'h3',
          head3Medium: 'h3',
          head3Regular: 'h3',
          head4Bold: 'h4',
          head4Medium: 'h4',
          head4Regular: 'h4',
          head5Bold: 'h5',
          head5Medium: 'h5',
          head5Regular: 'h5',
          headlineSmallSemiBold: 'h4',

          h2_700: 'h2',
          h3_700: 'h3',
          body1Bold: 'p',
          body1Medium: 'p',
          body1Regular: 'p',
          body2Bold: 'p',
          body2Medium: 'p',
          body2Regular: 'p',
          body3Bold: 'p',
          body3Medium: 'p',
          body3Regular: 'p',
          body4Bold: 'p',
          body4Medium: 'p',
          body4Regular: 'p',
          label1Bold: 'p',
          label1Medium: 'p',
          label1Regular: 'p',
          label2Bold: 'p',
          label2Medium: 'p',
          label2Regular: 'p',

          body3_400: 'p',
          body4_400: 'p',
          body4_600: 'p',
          body4_700: 'p',
          body5_700: 'p',
        },
      },
      styleOverrides: {
        root: {
          // lineHeight: 1.25,
          color: '#000',
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: '20px',
          paddingRight: '20px',
          [breakpoints.up('md')]: {
            paddingLeft: '40px',
            paddingRight: '40px',
          },
          [breakpoints.up('lg')]: {
            paddingLeft: '118px',
            paddingRight: '118px',
          },
          [breakpoints.up('xl')]: {
            paddingLeft: '118px',
            paddingRight: '118px',
          },
        },
      },
    },
    // MuiCssBaseline: {
    //   styleOverrides: {
    //     '@font-face': {
    //       fontFamily: 'Urbanist',
    //       fontStyle: 'normal',
    //       fontDisplay: 'swap',
    //       src: `local('Urbanist'), local('Urbanist-Regular'), url(${UrbanistRegularWoff2}) format('woff2')`,
    //       unicodeRange:
    //         'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
    //     },
    //     lineHeight: 1.25,
    //   },
    // },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          verticalAlign: 'middle',
          '& path': {
            // fill: 'currentColor !important',
          },
        },
      },
    },
    MuiButton,

    MuiInput: {
      styleOverrides: {
        root: {
          color: '#000',
          '& .MuiInputBase-input': {
            height: '31px',
            lineHeight: '32px',
          },
          '& .MuiSelect-select': {
            height: '31px',
            lineHeight: '32px',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: colorThemeOptions.palette?.ref?.gray['60'],
          fontSize: '16px',

          '&.MuiInputLabel-filled': {
            fontSize: '18px',
            transform: 'translate(16px, 16px) scale(1)',
            [breakpoints.down('xl')]: {
              transform: 'translate(16px, 12px) scale(1)',
            },
            '&.MuiInputLabel-shrink': {
              transform: 'translate(16px, 7px) scale(.67)',
              [breakpoints.down('xl')]: {
                transform: 'translate(16px, 5px) scale(.67)',
              },
            },
          },
          '&.Mui-focused': {
            color: colorThemeOptions.palette?.ref?.gray['60'],
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: '1px',

          '&>span': {
            lineHeight: '1.4em',
            minHeight: '1.4em',
          },
          '& .MuiSvgIcon-root': {
            fontSize: '12px',
            marginRight: '8px',
            verticalAlign: 'baseline',
          },
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          '& .MuiSvgIcon-root': {
            fontSize: '24px',
            color: '#444444',
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          background: '#fff',
          borderRadius: '10px',
          overflow: 'hidden',
          fontSize: '18px',

          '&:before': {
            top: 0,
            border: '1px solid #D7D7D7',
            borderRadius: '10px',
          },
          '&.Mui-disabled:before': {
            borderBottomStyle: 'solid',
          },
          '&.Mui-error:before': {
            borderColor: colorThemeOptions.palette?.ref?.error['60'],
          },
          '&:after': {
            content: 'none',
          },
          '&:hover:not(.Mui-disabled), &.Mui-focused': {
            backgroundColor: '#fff',
          },

          '&.Mui-focused:before, &:hover:not(.Mui-disabled):before': {
            borderColor: primary.main,
          },
          '.MuiFilledInput-input, textarea': {
            paddingLeft: '16px',
            paddingRight: '16px',
            height: '24px',
            lineHeight: '24px',
            paddingBottom: '7px',

            [breakpoints.down('xl')]: {
              paddingTop: '19px',
            },
            '&:-webkit-autofill': {
              borderRadius: 0,
            },
            '&:focus': {
              backgroundColor: 'initial',
            },
          },
          '.MuiSelect-icon': {
            color: colorThemeOptions.palette?.ref?.gray['30'],
          },
          '.MuiSelect-select[aria-expanded=true]~.field-end .field-selectArrow':
            {
              transform: 'rotate(180deg)',
            },
          '&.MuiInputBase-multiline': {
            padding: '0!important',
          },
          textarea: {
            minHeight: '2.8em',
            lineHeight: '1.4em',
            marginTop: '4px',
            paddingTop: '19px',
            bgc: 'red',
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          '&:hover:not(.Mui-disabled)': {
            color: colorThemeOptions.palette?.ref?.primary['60'],
          },
        },
      },
    },

    // MuiUseMediaQuery: {
    //   defaultProps: {
    //     noSsr: true,
    //   },
    // },
  },
})

export default responsiveFontSizes(theme, {
  factor: 5,
})
