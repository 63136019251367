// import '@/guanceInit'
import { isDev } from '@/utils'
import { CacheProvider, EmotionCache } from '@emotion/react'
import { CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { BASE_URL } from 'constants/config/api'
// import { Manrope } from '@next/font/google'
import theme from 'constants/theme'
import createEmotionCache from 'constants/theme/createEmotionCache'
import Cookies from 'js-cookie'
import { NextPage } from 'next'
import type { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import Script from 'next/script'
import NProgress from 'nprogress'
import 'public/nprogress.css'
import { ReactElement, ReactNode, useEffect } from 'react'
import TagManager from 'react-gtm-module'
import 'src/shared/styles/globals.css'
import 'src/utils/appAuth'
import fbq from 'src/utils/fpixel'
import { GTM_ID, setSitePage } from 'src/utils/gtm'

// const manrope = Manrope({ subsets: ['latin'] })

type CustomNextPage = NextPage & {
  sitePage?: Record<string, unknown>
  /**
   * 获取页面容器
   */
  getLayout?: (page: ReactElement, pageProps?: any) => ReactNode
}

const clientSideEmotionCache = createEmotionCache()

type MyAppProps = AppProps & {
  emotionCache?: EmotionCache
  Component: CustomNextPage
}

function MyApp({
  Component,
  pageProps,
  emotionCache = clientSideEmotionCache,
}: MyAppProps) {
  // 进度条
  const router = useRouter()

  // 记录utm_source、utm_medium、utm_campaign
  useEffect(() => {
    if (
      router.query.utm_source &&
      router.query.utm_medium &&
      router.query.utm_campaign
    ) {
      Cookies.set('utm_source', router.query.utm_source, {
        domain: '.dreo.com',
      })
      Cookies.set('utm_medium', router.query.utm_medium, {
        domain: '.dreo.com',
      })
      Cookies.set('utm_campaign', router.query.utm_campaign, {
        domain: '.dreo.com',
      })
    }
  }, [router])

  useEffect(() => {
    const handleStart = () => {
      NProgress.start()
    }
    const handleStop = () => {
      NProgress.done()
    }

    router.events.on('routeChangeStart', handleStart)
    router.events.on('routeChangeComplete', handleStop)
    router.events.on('routeChangeError', handleStop)

    return () => {
      router.events.off('routeChangeStart', handleStart)
      router.events.off('routeChangeComplete', handleStop)
      router.events.off('routeChangeError', handleStop)
    }
  }, [router])

  useEffect(() => {
    // This pageView only triggers the first time (it's important for Pixel to have real information)
    fbq.pageView()

    const handleRouteChange = () => {
      fbq.pageView()
    }

    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  useEffect(() => {
    const handleRouterChange = () => {
      // 设置cj需要的页面数据
      setSitePage(Component.sitePage ?? {})
    }

    router.events.on('routeChangeComplete', handleRouterChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouterChange)
    }
  }, [Component.sitePage, router])

  // useEffect(() => {
  //   const handleRouteChange = (url: string) => {
  //     pageView(url)
  //   }
  //   router.events.on('routeChangeComplete', handleRouteChange)
  //   router.events.on('hashChangeComplete', handleRouteChange)
  //   return () => {
  //     router.events.off('routeChangeComplete', handleRouteChange)
  //     router.events.off('hashChangeComplete', handleRouteChange)
  //   }
  // }, [router.events])

  useEffect(() => {
    TagManager.initialize({ gtmId: `${GTM_ID}` })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <CacheProvider value={emotionCache}>
      {/* {process.env.NEXT_PUBLIC_GOOGLE_TAG_UA_ID && (
        <Script
          src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_TAG_UA_ID}`}
          strategy="afterInteractive"
          onLoad={() => {
            window.dataLayer = window.dataLayer || [];
              // eslint-disable-next-line prefer-rest-params
              // function gtag(){window.dataLayer.push(arguments);}
              // gtag('js', new Date());

              // gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_TAG_UA_ID}');

              window.dataLayer.push(['js', new Date()]);

              process.env.NEXT_PUBLIC_GOOGLE_TAG_UA_ID && window.dataLayer.push(['config', `${process.env.NEXT_PUBLIC_GOOGLE_TAG_UA_ID}`]);
              process.env.NEXT_PUBLIC_GOOGLE_ADS_ID && window.dataLayer.push(['config', `${process.env.NEXT_PUBLIC_GOOGLE_ADS_ID}`]);
          }}
        ></Script>
      )} */}

      {/* {GTM_ID && (
        <>
          <Script id="gtm">{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${GTM_ID}');`}</Script>
        </>
      )} */}

      {/* {process.env.NEXT_PUBLIC_GOOGLE_TAG_UA_ID && (
        <Script
          src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_TAG_UA_ID}`}
          strategy="afterInteractive"
          id="ua-gtag"
        ></Script>
      )} */}

      {/* {process.env.NEXT_PUBLIC_GOOGLE_ADS_ID && (
        <Script
          strategy="afterInteractive"
          id="ads-gtag"
          src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ADS_ID}`}
          onLoad={() => {
            TagManager.initialize({ gtmId: `${GTM_ID}` })
          }}
        ></Script>
      )} */}

      {/* {process.env.NEXT_PUBLIC_GOOGLE_TAG_UA_ID && (
        <Script strategy="afterInteractive" id="google-gtag-ua">
          {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_TAG_UA_ID}');
            `}
        </Script>
      )} */}

      {/* {process.env.NEXT_PUBLIC_GOOGLE_ADS_ID && (
        <Script strategy="afterInteractive" id="google-ads">
          {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ADS_ID}');
              gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_TAG_UA_ID}');
            `}
        </Script>
      )} */}

      {/* <!-- Hotjar Tracking Code for https://www.dreo.com --> */}
      {process.env.NEXT_PUBLIC_HJ_ID && (
        <Script
          strategy="afterInteractive"
          id="hotjar"
          dangerouslySetInnerHTML={{
            __html: `(function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:${process.env.NEXT_PUBLIC_HJ_ID},hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
              })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=')`,
          }}
        ></Script>
      )}

      <Script
        strategy="afterInteractive"
        id="googleoptimize"
        src="https://www.googleoptimize.com/optimize.js?id=OPT-MZMW2SK"
      ></Script>

      {/* luckyorange */}
      {/* {process.env.NEXT_PUBLIC_LUCKY_ORANGE_ID && (
        <Script
          strategy="afterInteractive"
          id="luckyorange"
          defer
          src={`https://tools.luckyorange.com/core/lo.js?site-id=${process.env.NEXT_PUBLIC_LUCKY_ORANGE_ID}`}
        ></Script>
      )} */}

      {/* clarity.microsoft */}
      {!isDev && process.env.NEXT_PUBLIC_MICROSOFT_CLARITY_ID && (
        <Script
          strategy="afterInteractive"
          id="impact-tag"
          dangerouslySetInnerHTML={{
            __html: `(function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "${process.env.NEXT_PUBLIC_MICROSOFT_CLARITY_ID}");`,
          }}
        />
      )}

      {/* <!-- CJ --> */}
      {!['development', 'dev', 'fat', 'uat'].includes(
        `${process.env.NEXT_PUBLIC_WEB_APP_ENV}`
      ) && (
        <Script
          strategy="afterInteractive"
          id="cj-tag"
          dangerouslySetInnerHTML={{
            __html: `(function(a,b,c,d){ a='/api/cj/tags/389573193653/tag.js'; b=document;c='script';d=b.createElement(c);d.src=a; d.type='text/java'+c;d.async=true;
        d.id='cjapitag'; a=b.getElementsByTagName(c)[0];a.parentNode.insertBefore(d,a) })();`,
          }}
        ></Script>
      )}

      {/* <!-- impact --> */}
      {!['development', 'dev', 'fat'].includes(
        `${process.env.NEXT_PUBLIC_WEB_APP_ENV}`
      ) && (
        <>
          <Script
            strategy="afterInteractive"
            id="impact-tag"
            dangerouslySetInnerHTML={{
              __html: `(function(a,b,c,d,e,f,g){e['ire_o']=c;e[c]=e[c]||function(){(e[c].a=e[c].a||[]).push(arguments)};f=d.createElement(b);g=d.getElementsByTagName(b)[0];f.async=1;f.src=a;g.parentNode.insertBefore(f,g);})('//d.impactradius-event.com/A3687382-c5da-4e7f-aed8-6359f7918a3c1.js','script','ire',document,window); `,
            }}
          ></Script>
          <Script
            strategy="afterInteractive"
            id="impact-identify-tag"
            dangerouslySetInnerHTML={{
              __html: `ire('identify', {customerId: '', customerEmail: ''});`,
            }}
          ></Script>
        </>
      )}
      {/* <!-- guance --> */}
      {process.env.NEXT_PUBLIC_GUANCEYUN_APP_ID && (
        <>
          <Script
            strategy="afterInteractive"
            id="guance-tag"
            dangerouslySetInnerHTML={{
              __html: ` (function (h, o, u, n, d) {
                h = h[d] = h[d] || {
                q: [],
                onReady: function (c) {
                    h.q.push(c)
                }
                }
                d = o.createElement(u)
                d.async = 1
                d.src = n
                n = o.getElementsByTagName(u)[0]
                n.parentNode.insertBefore(d, n)
            })(
                window,
                document,
                'script',
                'https://static.guance.com/browser-sdk/v3/dataflux-rum.js',
                'DATAFLUX_RUM'
            )
            DATAFLUX_RUM.onReady(function () {
                DATAFLUX_RUM.init({
                applicationId: '${process.env.NEXT_PUBLIC_GUANCEYUN_APP_ID}',
                datakitOrigin: '${
                  process.env.NEXT_PUBLIC_DATAKIT_URL
                }', // 协议（包括：//），域名（或IP地址）[和端口号],
                env: '${process.env.NEXT_PUBLIC_WEB_APP_ENV}',
                version: '1.0.0',
                service: 'dreo-official-website-${
                  process.env.NEXT_PUBLIC_WEB_APP_ENV
                }',
                sessionSampleRate: 100,
                sessionReplaySampleRate: 70,
                trackInteractions: true,
                allowedTracingOrigins: ['${BASE_URL?.replace('/api', '')}'],
                traceType: 'skywalking_v3', // 非必填，默认为ddtrace，目前支持 ddtrace、zipkin、skywalking_v3、jaeger、zipkin_single_header、w3c_traceparent 6种类型
                });
                // window.DATAFLUX_RUM && window.DATAFLUX_RUM.startSessionReplayRecording()
            });
            `,
            }}
          ></Script>
        </>
      )}

      {/* viktoria平台代码 */}
      {!['development', 'dev', 'fat'].includes(
        `${process.env.NEXT_PUBLIC_WEB_APP_ENV}`
      ) && (
        <Script
          strategy="afterInteractive"
          id="viktoria-tag"
          dangerouslySetInnerHTML={{
            __html: `var AddShoppersWidgetOptions = { 'loadCss': false, 'pushResponse': false };
              (!function(){
                  var t=document.createElement("script");
                  t.type="text/javascript",
                  t.async=!0,
                  t.id="AddShoppers",
                  t.src="https://shop.pe/widget/widget_async.js#64df23a1955209eeb85ce382",
                  document.getElementsByTagName("head")[0].appendChild(t)
              }());`,
          }}
        ></Script>
      )}

      {/* Global Site Code Pixel - Facebook Pixel */}
      <Script
        id="fb-pixel"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', ${fbq.FB_PIXEL_ID});
          `,
        }}
      />

      <ThemeProvider theme={theme}>
        <CssBaseline />
        {/* <AnimatePresence exitBeforeEnter onExitComplete={handleExitComplete}>
      {children}
    </AnimatePresence> */}
        {/* <style jsx global>{`
          html {
            font-family: ${manrope.style.fontFamily};
          }
        `}</style>
         */}
        {Component?.getLayout ? (
          Component.getLayout(<Component {...pageProps} />, pageProps)
        ) : (
          <Component {...pageProps} />
        )}
      </ThemeProvider>
    </CacheProvider>
  )
}

export default MyApp
