import { configureStore } from '@reduxjs/toolkit'
import { checkoutSlice } from './checkout.slice'
import { announcementBarSlice } from './announcementBar.slice'
import { messageSlice } from './message.slice'

export const rootReducer = {
  checkout: checkoutSlice.reducer,
  announcementBar: announcementBarSlice.reducer,
  message: messageSlice.reducer,
}

export const store = configureStore({
  reducer: rootReducer,
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
