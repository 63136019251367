import { breakpoints } from './breakpoints'
import { Breakpoint } from '@mui/material/styles'
import {
  TypographyStyleOptions,
  CSSProperties,
} from '@mui/material/styles/createTypography'

type TypographyType =
  | 'displayLarge'
  | 'displayMedium'
  | 'displaySmall'
  | 'headlineLarge'
  | 'headlineMedium'
  | 'headlineSmall'
  | 'titleLarge'
  | 'titleMedium'
  | 'titleSmall'
  | 'bodyLarge'
  | 'bodyMedium'
  | 'bodySmall'
  | 'labelLarge'
  | 'labelMedium'
  | 'labelSmall'

const typographyFontSizeConfig: {
  [K in TypographyType]: { [FK: string]: number }
} = {
  displayLarge: { xs: 40, md: 44, lg: 50, xl: 56 },
  displayMedium: { xs: 28, md: 32, lg: 38, xl: 44 },
  displaySmall: { xs: 24, md: 28, lg: 32, xl: 36 },

  headlineLarge: { xs: 24, md: 26, lg: 28, xl: 32 },
  headlineMedium: { xs: 20, md: 22, lg: 24, xl: 26 },
  headlineSmall: { xs: 16, md: 18, lg: 20, xl: 20 },

  titleLarge: { xs: 20, md: 20, lg: 24, xl: 24 },
  titleMedium: { xs: 16, md: 16, lg: 18, xl: 18 },
  titleSmall: { xs: 13, md: 13, lg: 14, xl: 16 },

  bodyLarge: { xs: 16, md: 16, lg: 16, xl: 16 },
  bodyMedium: { xs: 13, md: 13, lg: 14, xl: 14 },
  bodySmall: { xs: 12, md: 12, lg: 12, xl: 12 },

  labelLarge: { xs: 16, md: 16, lg: 18, xl: 18 },
  labelMedium: { xs: 14, md: 14, lg: 14, xl: 14 },
  labelSmall: { xs: 12, md: 12, lg: 12, xl: 12 },
}

const lineHeight150Components = ['bodySmall', 'bodyMedium', 'bodyLarge']
const lineHeight100Components = ['labelSmall', 'labelMedium', 'labelLarge']

const typographyThemeStyle: { [K in TypographyType]?: TypographyStyleOptions } =
  {}

// 转换为TypographyStyleOptions
for (const [key, fontSizeByBreakPoint] of Object.entries(
  typographyFontSizeConfig
)) {
  const temp: TypographyStyleOptions = {}
  for (const [breakPoint, fontSize] of Object.entries(fontSizeByBreakPoint)) {
    if (breakPoint === 'xs') {
      temp.fontSize = fontSize
      if (lineHeight150Components.includes(key)) {
        temp.lineHeight = 1.5
      } else if (lineHeight100Components.includes(key)) {
        temp.lineHeight = 1
      } else {
        temp.lineHeight = 1.25
      }
    } else {
      temp[breakpoints.up(breakPoint as Breakpoint)] = { fontSize }
    }
  }
  typographyThemeStyle[key as TypographyType] = temp
}

const mergeCSSPropertiesHandler = (
  type: string,
  cssProperties: CSSProperties
) => {
  const mergeCSSProperties: { [K in string]?: TypographyStyleOptions } = {}
  Object.keys(typographyThemeStyle).forEach((key) => {
    if (typographyThemeStyle[key as TypographyType]) {
      const textType = `${key}${type}`
      let temp = {
        ...typographyThemeStyle[key as TypographyType],
        ...cssProperties,
      }

      // 特殊处理的样式
      if (textType.includes('display')) {
        temp = { ...temp, letterSpacing: '-1px' }
      } else if (/(headlineMedium)|(headlineLarge)/.test(textType)) {
        temp = { ...temp, letterSpacing: '-0.001em' }
      } else if (/(titleSmall)|(labelMedium)/.test(textType)) {
        temp = { ...temp, letterSpacing: '0.001em' }
      } else if (/(bodyLarge)|(bodySmall)/.test(textType)) {
        temp = { ...temp, letterSpacing: '-0.1px' }
      } else if (/(labelLarge)/.test(textType)) {
        temp = { ...temp, letterSpacing: '0.002em' }
      }

      mergeCSSProperties[textType] = temp
    }
  })
  return mergeCSSProperties
}

export const typographyCSSProperties = typographyThemeStyle

// 添加字重
export const bold = mergeCSSPropertiesHandler('Bold', { fontWeight: 700 })
export const semiBold = mergeCSSPropertiesHandler('SemiBold', {
  fontWeight: 600,
})
export const regular = mergeCSSPropertiesHandler('Regular', { fontWeight: 400 })

export const MuiTypographyVariantMapping = Object.keys({
  ...bold,
  ...semiBold,
  ...regular,
}).reduce((prev, cur) => {
  prev[cur] = 'p'
  return prev
}, {} as { [K in string]: string })
