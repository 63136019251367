import { createSlice } from '@reduxjs/toolkit'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { RootState } from './index'
import Cookies from 'js-cookie'

interface State {
  showAnnouncementBar: boolean
}

const isHideCookieKey = '__IS_HIDE_ANNOUNCEMENT_BAR__'

const showAnnouncementBar = Cookies.get(isHideCookieKey) ? false : true

const initialState: State = {
  showAnnouncementBar,
}

export const announcementBarSlice = createSlice({
  name: 'announcementBar',
  initialState,
  reducers: {
    // 关闭
    hideAnnouncementBar(state) {
      state.showAnnouncementBar = false
      Cookies.set(isHideCookieKey, 'hide', { expires: 1 })
    },
  },
})

export const announcementBarActions = announcementBarSlice.actions

export const useClose = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(announcementBarActions.hideAnnouncementBar())
  }, [dispatch])
}

export const selectAnnouncementBar = (state: RootState) =>
  state.announcementBar.showAnnouncementBar
