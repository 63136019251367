import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { RootState } from './index'
import { OptionsObject, SnackbarMessage } from 'notistack'

interface SnackbarMsgProps {
  message: SnackbarMessage
  options?: OptionsObject
}

interface State {
  showMessage: boolean
  msg: string
  duration?: number
  snackbarMsgList: SnackbarMsgProps[]
}

const initialState: State = {
  showMessage: false,
  msg: '',
  duration: 3000,
  snackbarMsgList: [],
}

export const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    // 关闭
    hideMessage(state) {
      state.showMessage = false
    },
    // 开启
    showMessage(
      state,
      action: PayloadAction<{ msg: string; duration?: number }>
    ) {
      const options: SnackbarMsgProps['options'] = {
        variant: 'warning',
      }
      const { duration } = action.payload
      if (duration) {
        options.autoHideDuration = duration
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state.snackbarMsgList = [
        ...state.snackbarMsgList,
        { message: action.payload.msg, options: options },
      ] as SnackbarMsgProps[]
    },
    showSnackbar(state, action: PayloadAction<SnackbarMsgProps>) {
      // todo TS2322: Type 'SnackbarMsgProps[]' is not assignable to type 'WritableDraft []'.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state.snackbarMsgList = [
        ...state.snackbarMsgList,
        action.payload,
      ] as SnackbarMsgProps[]
    },
    clearSnackbarList(state) {
      state.snackbarMsgList = []
    },
  },
})

export const messageActions = messageSlice.actions

export const useClose = () => {
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(messageActions.hideMessage())
  }, [dispatch])
}

export const useShowMessage = () => {
  const dispatch = useDispatch()
  return useCallback(
    (msg: string, duration?: number) => {
      dispatch(
        messageActions.showMessage({
          msg,
          duration: duration ?? 3000,
        })
      )
    },
    [dispatch]
  )
}

export const selectMessageState = (state: RootState) =>
  state.message.showMessage

export const selectMessage = (state: RootState) => state.message.msg

export const selectDuration = (state: RootState) =>
  state.message.duration ?? 3000

export const snackbarMsgList = (state: RootState) =>
  state.message.snackbarMsgList
