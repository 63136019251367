import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { useMemo } from 'react'
import { Breakpoint } from '@mui/system/createTheme/createBreakpoints'

export type { Breakpoint } from '@mui/system/createTheme/createBreakpoints'

/**
 *
 * @returns 当前媒体查询的值
 */
const useCurrentMediaQuery = (): Breakpoint => {
  const theme = useTheme()
  const mdMatches = useMediaQuery(theme.breakpoints.up('md'))
  const lgMatches = useMediaQuery(theme.breakpoints.up('lg'))
  const xlMatches = useMediaQuery(theme.breakpoints.up('xl'))
  return useMemo(() => {
    if (xlMatches) {
      return 'xl'
    } else if (lgMatches) {
      return 'lg'
    } else if (mdMatches) {
      return 'md'
    } else {
      return 'xs'
    }
  }, [xlMatches, lgMatches, mdMatches])
}

export default useCurrentMediaQuery
export { useCurrentMediaQuery }
