/*
 * @description:
 */
import { setUser } from '@sentry/nextjs'
import { oauthConfig } from 'constants/config'
import {
  EMAIL_VERIFY,
  OAUTH_LOGIN,
  OAUTH_LOGOUT,
  USER_REGISTER_EMAIL,
} from 'constants/config/api'
import Cookies from 'js-cookie'
import httpClient, { HttpData } from 'src/utils/helpers/http'
import { LoginResponse, RegisterResponse } from 'types/account'

export const cookieKey = '__user_auth_token__'
export const appTokenCookieKey = '__app_token__'
export const oauthCookieKey = '__oauth_token__'

// 查询是否存储了用户id和昵称 如果存在 则设置到sentry数据中
const userId = Cookies.get('user_id')
const userNickname = Cookies.get('nickname')

if (userId) {
  // 添加sentry用户信息
  setUser({
    // email: `${user?.email}`,
    id: `${userId}`,
    username: `${userNickname}`,
  })
}

// 获取token
export const getToken = () =>
  Cookies.get(cookieKey) || (typeof window !== 'undefined' && window.h5Token)

// 保存token
export const handleSaveToken = (token: string, expires: number) =>
  Cookies.set(cookieKey, token, {
    expires,
  })

// 获取App token
export const getAppToken = () =>
  Cookies.get(appTokenCookieKey) ||
  (typeof window !== 'undefined' && window.h5Token)

// 保存token
export const handleSaveAppToken = (token: string, expires: number) =>
  Cookies.set(appTokenCookieKey, token, {
    expires,
  })

export const removeUserToken = () => {
  Cookies.remove(cookieKey)
}

export const getOauthToken = () => Cookies.get(oauthCookieKey)

export const handleSaveOauthToken = (token: string, expires: number) =>
  Cookies.set(oauthCookieKey, token, {
    expires,
  })

export const removeOauthToken = () => {
  Cookies.remove(oauthCookieKey)
}

/**
 * 登录
 * @param data
 * @returns
 */
export const login = (data: {
  email: string
  password: string
  himei: string
}) =>
  httpClient.post<HttpData<LoginResponse>>(OAUTH_LOGIN, {
    ...oauthConfig,
    grant_type: 'email-password',
    ...data,
  })

/**
 * 注册
 * @param data
 * @returns Promise<AxiosResponse<any>>
 */
export const register = (data: {
  email: string
  password: string
  nickname: string
}) => httpClient.post<HttpData<RegisterResponse>>(USER_REGISTER_EMAIL, data)

/**
 * 注销
 * @returns
 */
export const logout = async () => {
  const res = await httpClient.post<HttpData<RegisterResponse>>(
    `${OAUTH_LOGOUT}&timestamp=${new Date()}`,
    {}
  )
  if (res.code === 0) {
    removeUserToken()
  }
  return res
}

export const sendEmailVerifyCode = (data: { retry?: boolean }) =>
  httpClient.post<HttpData>(EMAIL_VERIFY, data ?? {})
