import { createTheme } from '@mui/material/styles'

export const breakpointsValues = {
  xs: 0,
  md: 768,
  lg: 992,
  xl: 1440,
}

export const customTheme = createTheme({
  breakpoints: {
    values: breakpointsValues,
  },
})

export const breakpoints = customTheme.breakpoints

// 可用 断点 key 类型
export type Breakpoints = keyof typeof breakpointsValues
