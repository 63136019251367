import * as Sentry from '@sentry/nextjs'
import axios from 'axios'
import Router from 'next/router'
import { refreshOauthToken, refreshUserToken } from 'src/api/account'
import {
  getAppToken,
  getOauthToken,
  getToken,
  handleSaveOauthToken,
  handleSaveToken,
  removeOauthToken,
  removeUserToken,
} from 'src/providers/auth-provider'
import { store } from 'src/store'
import { messageActions } from 'src/store/message.slice'
import { getBaseUrl } from './../../../constants/config/api'

// console.log(process.env.NEXT_PUBLIC_API_URL, process.env.NODE_ENV, process.env.SECRET_COOKIE_PASSWORD)
const httpClient = axios.create({
  timeout: 18000,
  // baseURL: process.env.NEXT_PUBLIC_API_URL,
})

// const methodsNoData = ['delete', 'get', 'head', 'options']

// 标记是否正在刷新
let isRefreshing = false

const releaseVersion = process.env.RELEASE_VERSION

// 需要重新请求的队列
let requests: ((token: string) => void)[] = []

const globalResCodes = [
  400, // Bad Request
  403, // Forbidden
  404, // Not Found
  405, // Method Not Allowed
  500, // Internal Server Error
  100001, // Null Pointer
  100002, // Class Cast
  100003, // Io Error
  100004, // No Such Method
  100005, // Array Index Out Of Bounds
  100006, // Http Message Not Readable
  100007, // Method Argument Type Mismatch
  100008, // Missing Servlet Request Parameter
  100009, // bind Not Valid
  100010, // Method Argument Not Valid
  100011, // Http Request Method Not Supported
  100012, // Http Media Type Not Acceptable
  100013, // Http Media Type Not Supported
  100014, // No Handler Found
  100015, // biz error
  100016, // service error
  100017, // other error
  100018, // invalid client
  100019, // unsupported grant type
  100020, // invalid scope
]

declare global {
  interface Window {
    // app传过来的token
    appToken?: string
    h5Token?: string
  }
}

/**
 * 请求拦截器
 */
httpClient.interceptors.request.use(
  (config) => {
    // 自定义头部字段hs-user-agent:website/${version} 用于通过waf防火墙拦截

    const oriHeaders = { ...config.headers }

    config.headers['hs-user-agent'] = `website/${releaseVersion}`
    config.headers['UA'] = `dreo/${releaseVersion}(official website)/`

    // 用户token
    let token = getToken()

    // appToken
    const appToken = getAppToken()

    if (typeof window !== 'undefined') {
      if (appToken) {
        token = appToken
        handleSaveToken(appToken, 0)
      }
    }

    // OauthToken
    const oauthToken = getOauthToken()
    // if (config.method === 'get') {
    //   console.log(config)
    //   config.headers['content-type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    // }

    // 自定义头部字段hs-user-agent:website/${version} 用于通过waf防火墙拦截
    config.headers['hs-user-agent'] = `website/${releaseVersion}`

    if (config.method === 'post' || config.method === 'put') {
      if (!config.data) config.data = {}
    }
    // console.log(config)
    // 携带token
    if ((token || oauthToken) && !config.url?.includes('/oauth/login')) {
      config.headers['Authorization'] = `Bearer ${token ? token : oauthToken}`
    }

    config.headers = {
      ...config.headers,
      oriHeaders,
    }

    if (config.params) {
      config.params.timestamp = new Date().getTime()
    } else {
      config.params = {
        timestamp: new Date().getTime(),
      }
    }

    // 如果是App服务端直接调用则使用App接口
    if (config.headers['is_app']) {
      config.url = `${process.env.NEXT_PUBLIC_APP_H5_API_URL}${config.url}`
    } else {
      config.url = `${config.url?.includes('http') ? '' : getBaseUrl()}${
        config.url
      }`
    }

    return config
  },
  (error) => {
    console.log(error)
    Sentry.captureException(
      new Error(error ? JSON.stringify(error) : 'Network error')
    )
    return Promise.reject(error)
  }
)

const handleToLogin = () => {
  //提示登录失效
  store.dispatch(
    messageActions.showSnackbar({
      message: 'Account login is invalid！',
      options: {
        variant: 'warning',
        key: 'Unauthorized',
        autoHideDuration: 4000,
        preventDuplicate: true,
      },
    })
  )

  // 删除用户token
  removeUserToken()
  // 删除客户端token
  // removeOauthToken()
  // 跳转到登录页
  Router.push('/account')
}

/**
 * 响应拦截器
 */
httpClient.interceptors.response.use(
  (response) => {
    const res = response.data

    if (res.code === 0) return res
    // if (res.code !== 0) return Promise.reject(res)

    // token失效
    if (res.code === 401) {
      // 用户token
      const token = getToken()
      // const oauthToken = getOauthToken()
      const refreshToken =
        typeof window !== 'undefined'
          ? localStorage.getItem('refresh_token') ?? ''
          : ''
      if (refreshToken && token) {
        // 刷新用户token
        if (!isRefreshing) {
          isRefreshing = true

          // const refreshToken = localStorage.getItem('refresh_token') ?? ''
          const configUrl = new URL(response.config.url as string)

          // 调用刷新token的接口
          return refreshUserToken(refreshToken, configUrl.origin)
            .then(({ data: result }) => {
              if (result.code !== 0) {
                requests = []
                isRefreshing = false
                // handleToLogin()
              } else {
                // 替换token
                handleSaveToken(
                  result.data?.access_token ?? '',
                  result.data?.expires_in ?? 0
                )
                response.headers.Authorization = `Bearer ${
                  result.data?.access_token ?? ''
                }`

                // token 刷新后将数组的方法重新执行
                requests.forEach((cb) => cb(result.data?.access_token ?? ''))
                requests = [] // 重新请求完清空
                return httpClient(response.config)
              }
            })
            .catch((err) => {
              // handleToLogin()
              return Promise.reject(err)
            })
            .finally(() => {
              isRefreshing = false
            })
        } else {
          return new Promise((resolve) => {
            requests.push((token: string) => {
              response.headers.authorization = `Bearer ${token}`
              isRefreshing = false
              resolve(httpClient(response.config))
            })
          })
        }
      } else {
        // 刷新oauthToken
        isRefreshing = true
        // 调用刷新token的接口
        return refreshOauthToken()
          .then(({ data }) => {
            isRefreshing = false
            // 替换token
            handleSaveOauthToken(
              data?.access_token ?? '',
              data?.expires_in ?? 0
            )
            response.headers.Authorization = `Bearer ${
              data?.access_token ?? ''
            }`
            // token 刷新后将数组的方法重新执行
            requests.forEach((cb) => cb(data?.access_token ?? ''))
            requests = [] // 重新请求完清空
            return httpClient(response.config)
          })
          .catch((err) => {
            //跳到登录页
            handleToLogin()

            // 删除用户oauth token
            removeOauthToken()
            // 跳转到登录页
            return Promise.reject(err)
          })
          .finally(() => {
            isRefreshing = false
          })
      }
    } else if (globalResCodes.includes(res.code)) {
      // Sentry.captureException(new Error(JSON.stringify(res)));
      const err = new Error(
        `Api Call to ${response.config.url} failed: ${res.code} ${res.msg}`
      )

      Sentry.withScope((scope) =>
        scope.setTags({ statusCode: res.code, statusText: res.msg })
      )

      Sentry.captureException(err)
    }
    return res
  },
  (error) => {
    Sentry.captureException(error)

    return Promise.reject({
      code: 500,
      msg: error.response?.data?.msg ?? 'Network Error',
    })
  }
)

export default httpClient

export interface HttpData<TResult = any> {
  code: number
  msg?: string
  data?: TResult
}
