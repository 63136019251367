import axios from 'axios'
import { appOauthConfig, oauthConfig } from 'constants/config'
import {
  OAUTH_LOGIN,
  PASSWORD_EMAIL_VERIFY,
  PASSWORD_RESET,
  PASSWORD_UPDATE,
  PRODUCT_WARRANTY,
  QUERY_IS_SUBSCRIBE,
  REFRESH_TOKEN,
  SHOPIFY_ACCESS_TOKEN,
  SUBSCRIBE,
  SUBSCRIBE_CES,
  SUPPORT_REQUEST,
  UPDATE_USER_INFO,
  USER_INFO,
  VERIFY_EMAIL_CODE,
} from 'constants/config/api'
import queryString from 'query-string'
import httpClient, { HttpData } from 'src/utils/helpers/http'
import {
  CESSubmitFormData,
  ForgotPasswordForm,
  LoginResponse,
  ProfileFormData,
  ResetPasswordFormData,
  SecurityFormData,
  SubscribeFormData,
  SupportParamsData,
  User,
  VerifyCodeData,
  WarrantyRequestData,
  WarrantyResponse,
} from 'types/account'

/**
 * 通过验证码激活当前用户
 * @param data VerifyCodeData
 * @returns
 */
export const verifyEmailCode = (data: VerifyCodeData) =>
  httpClient.post(VERIFY_EMAIL_CODE, data)

export const sentFindPasswordEmail = (data: ForgotPasswordForm) =>
  httpClient.post<HttpData>(PASSWORD_EMAIL_VERIFY, data)

/**
 * 修改密码
 * @param data SecurityFormData
 * @returns
 */
export const updatePassword = (data: SecurityFormData) =>
  httpClient.post<HttpData>(PASSWORD_UPDATE, { ...data, encrypt: true })

/**
 * 重置密码
 * @param data SecurityFormData
 * @returns
 */
export const resetPassword = (data: ResetPasswordFormData) =>
  httpClient.post<HttpData>(PASSWORD_RESET, { ...data, encrypt: true })

/**
 * 验证重置密码token
 * @param
 * @returns
 */
export const verifyPasswordToken = (params: Partial<ResetPasswordFormData>) =>
  httpClient.get<HttpData<boolean>>(PASSWORD_RESET, {
    params: { ...params, encrypt: true },
  })

/**
 * 获取认证token
 * @param data getOauthToken
 * @returns
 */
export const getOauthToken = () =>
  httpClient.post<
    HttpData<{
      access_token: string
      token_type: string
      expires_in: number
      scope: string
    }>
  >(OAUTH_LOGIN, oauthConfig)

/**
 * 获取A[[认证token
 * @param data getAppOauthToken
 * @returns
 */
export const getAppOauthToken = () =>
  httpClient.post<
    HttpData<{
      access_token: string
      token_type: string
      expires_in: number
      scope: string
    }>
  >(OAUTH_LOGIN, appOauthConfig, {
    headers: {
      is_app: true,
    },
  })

export const refreshOauthToken = () =>
  httpClient.post<
    HttpData<{
      access_token: string
      token_type: string
      expires_in: number
      scope: string
    }>
  >(OAUTH_LOGIN, oauthConfig)

/**
 * 刷新token
 * @param refreshToken
 * @returns
 */
// export const refreshUserToken = async (refreshToken: string) => {
//   const res = await httpClient.post<HttpData<LoginResponse>>(REFRESH_TOKEN, {
//     ...oauthConfig,
//     grant_type: 'refresh_token',
//     refresh_token: refreshToken,
//   })
//   console.log(res)
//   return res
// }
export const refreshUserToken = (refreshToken: string, baseUrl?: string) =>
  axios.post<{ data: HttpData<LoginResponse> }>(
    `${baseUrl ? baseUrl + '/api' : ''}${REFRESH_TOKEN}`,
    {
      ...oauthConfig,
      grant_type: 'refresh_token',
      refresh_token: refreshToken,
    },
    {
      headers: {
        'hs-user-agent': `website/${process.env.RELEASE_VERSION}`,
      },
    }
  )

/**
 * 获取用户信息
 * @returns
 */
export const getUserInfo = (token?: string) =>
  httpClient.get<HttpData<User>>(
    USER_INFO,
    token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : {}
  )

/**
 * 获取用户信息
 * @returns
 */
export const getUserInfoByAppH5Token = (baseUrl: string, token?: string) => {
  return axios.get<{ data: HttpData<User> }>(
    `${baseUrl}${USER_INFO}?token=${token}&timestamp=${new Date().getTime()}`,
    token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
            'hs-user-agent': `website/${process.env.RELEASE_VERSION}`,
            UA: `dreo/${process.env.RELEASE_VERSION}(official website)/`,
          },
        }
      : {}
  )
}

/**
 * 获取shopify access token
 * @returns
 */
export const getShopifyAccessToken = () =>
  httpClient.post<
    HttpData<{
      accessToken: string
      expiresAt: number
    }>
  >(SHOPIFY_ACCESS_TOKEN)

/**
 * 修改用户信息
 * @param data ProfileFormData
 * @returns
 */
export const updateUserInfo = (data: ProfileFormData) =>
  httpClient.put<HttpData<User>>(UPDATE_USER_INFO, data)

/**
 * 延保
 * @param data WarrantyFormData
 * @returns
 */
export const submitWarranty = (data: WarrantyRequestData) =>
  httpClient.post<HttpData<WarrantyResponse>>(PRODUCT_WARRANTY, data)
// httpClient.post<HttpData>('/api/warranty', data)
/**
 * 查询是否订阅
 * @param data WarrantyFormData
 * @returns
 */
export const queryIsSubscribe = (data: {
  email: string
  subscribeType: string
}) =>
  httpClient.get<HttpData<{ subscribed: boolean }>>(
    `${QUERY_IS_SUBSCRIBE}?email=${data.email}&subscribeType=${data.subscribeType}`
  )

/**
 * support request
 * @param data SupportParamsData
 * @returns
 */

export const submitSupportRequest = (
  data: SupportParamsData & { appendix?: string[] /** 附件 */ }
) => httpClient.post<HttpData>(SUPPORT_REQUEST, data)

/**
 * CES表单提交
 * @param data CESSubmitFormData
 * @returns
 */

export const submitCesForm = (data: CESSubmitFormData) =>
  httpClient.post<HttpData>(SUBSCRIBE_CES, data)

/**
 * Subscribe request
 * @param data SubscribeFormData
 * @returns
 */
export const submitSubscribe = (query: SubscribeFormData) =>
  httpClient.post<HttpData>(
    `${SUBSCRIBE}?${queryString.stringify({
      subscribeType: 'PRODUCT',
      ...query,
    })}`
  )

/**
 * Subscribe Recipes request
 * @param data SubscribeFormData
 * @returns
 */
export const submitSubscribeRecipes = (query: SubscribeFormData) =>
  httpClient.post<HttpData>(
    `${SUBSCRIBE}?${queryString.stringify({ ...query, subscribeType: 'MENU' })}`
  )

/** 地址管理 */
