import {
  Checkout,
  CheckoutLineItemEdge,
} from 'types/shopify-storefront-api-typings'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { RootState } from './index'

export type CheckoutData = Partial<Checkout> | undefined

export type CheckoutId = string
export type LineItems = CheckoutLineItemEdge[]

export interface CheckoutPayload {
  // id: CheckoutId;
  // lineItems: LineItems
  checkout?: Checkout
}

interface State extends CheckoutPayload {
  checkoutModal: boolean
}

const initialState: State = {
  checkoutModal: false,
  checkout: undefined,
  // id: '',
  // lineItems: []
}

export const checkoutSlice = createSlice({
  name: 'checkoutModal',
  initialState,
  reducers: {
    // 开启模态框
    openCheckoutModal(state) {
      state.checkoutModal = true
    },
    // 关闭模态框
    closeCheckoutModal(state) {
      state.checkoutModal = false
    },
    // 更新购物车
    updateCheckout(state, { payload }: PayloadAction<Checkout>) {
      state.checkout = payload
    },
    // 更新购物车Id
    // updateCheckoutId(state, {payload}: PayloadAction<{id: string}>) {
    //   state.id = payload.id;
    // },
    // // 更新购物车列表
    // updateCheckoutLineItems(state, { payload }: PayloadAction<{lineItems: LineItems}>) {
    //   state.lineItems = payload.lineItems;
    // },
  },
})

export const checkoutActions = checkoutSlice.actions

/**
 * 更新购物车列表hook
 * @param {checkout: CheckoutPayload}
 * @returns update checkout callback
 */
// export const useUpdateCheckoutLineItems = () => {
//   const dispatch = useDispatch()
//   return useCallback((lineItems: LineItems) => {
//     // 更新购物车
//     dispatch(checkoutActions.updateCheckoutLineItems({lineItems}))
//   }, [dispatch])
// }

/**
 * 更新购物车id hook
 * @param {checkout: CheckoutPayload}
 * @returns update checkout callback
 */
export const useUpdateCheckout = () => {
  const dispatch = useDispatch()
  return useCallback(
    (checkout: Checkout) => {
      // 更新购物车
      dispatch(checkoutActions.updateCheckout(checkout))
    },
    [dispatch]
  )
}

// 获取modal状态
export const selectCheckoutModalOpen = (state: RootState) =>
  state.checkout.checkoutModal

// 获取checkoutId
// export const selectCheckoutId = (state: RootState) => state.checkout.id

// 获取购物车列表
export const selectCheckout = (state: RootState) => state.checkout.checkout
