import { Components } from '@mui/material/styles/components'
import { buttonClasses } from '@mui/material/Button'
import color from '../color'

const { palette } = color

const buttonDisable = {
  textColor: palette?.ref?.gray?.['70'],
  bgColor: palette?.ref?.gray?.['99'],
  borderColor: palette?.ref?.gray?.['70'],
}

export default {
  styleOverrides: {
    root: {
      // 默认medium
      padding: '8px 32px',
      textTransform: 'none',
      borderRadius: '40px',
      lineHeight: 1.25,
      boxShadow: 'none',
      fontSize: 16,
      fontWeight: 500,
      '&.padding-normal': {
        padding: '8px 16px',
      },
      '&:hover, &:active': {
        boxShadow: 'none',
      },
      [`&.${buttonClasses.sizeLarge}:not(.${buttonClasses.textSizeLarge})`]: {
        padding: '10px 24px',
        lineHeight: 1.3,
        fontSize: 20,
      },
      [`&.${buttonClasses.sizeSmall}:not(.${buttonClasses.textSizeSmall})`]: {
        padding: '7px 16px',
      },
      [`&.${buttonClasses.containedSecondary}`]: {
        color: 'white',
      },
      [`&.${buttonClasses.outlinedInherit}`]: {
        color: palette?.ref?.gray?.['0'],
        backgroundColor: 'transparent',
        [`&:hover:not(.${buttonClasses.disabled})`]: {
          color: palette?.ref?.primary?.['40'],
          borderColor: palette?.ref?.primary?.['60'],
        },
        [`&:active:not(.${buttonClasses.disabled})`]: {
          color: palette?.ref?.primary?.['40'],
          borderColor: palette?.ref?.primary?.['40'],
        },
        [`&.${buttonClasses.disabled}`]: {
          color: buttonDisable.textColor,
          borderColor: buttonDisable.borderColor,
        },
      },

      [`&.${buttonClasses.containedPrimary}`]: {
        color: 'white',
        backgroundColor: palette?.ref?.primary?.['50'],
        border: '1px solid ' + palette?.ref?.primary?.['50'],

        [`&:hover:not(.${buttonClasses.disabled})`]: {
          borderColor: palette?.ref?.primary?.['60'],
          backgroundColor: palette?.ref?.primary?.['60'],
        },
        [`&:active:not(.${buttonClasses.disabled})`]: {
          borderColor: palette?.ref?.primary?.['40'],
          backgroundColor: palette?.ref?.primary?.['40'],
        },
        '&.MuiLoadingButton-loading.MuiButton-root': {
          backgroundColor: palette?.ref?.primary?.['50'],
          border: '1px solid ' + palette?.ref?.primary?.['50'],
        },
        '.MuiLoadingButton-loadingIndicatorCenter': {
          color: '#fff',
        },
      },
      [`&.${buttonClasses.outlinedPrimary}`]: {
        color: palette?.ref?.primary?.['50'],
        borderColor: palette?.ref?.primary?.['50'],

        [`&:hover:not(.${buttonClasses.disabled})`]: {
          color: palette?.ref?.primary?.['60'],
          backgroundColor: 'transparent',
          borderColor: palette?.ref?.primary?.['60'],
        },

        [`&:active:not(.${buttonClasses.disabled})`]: {
          color: palette?.ref?.primary?.['40'],
          backgroundColor: 'transparent',
          borderColor: palette?.ref?.primary?.['40'],
        },
      },
      [`&.${buttonClasses.textPrimary}`]: {
        color: palette?.ref?.primary?.['50'],

        [`&:hover:not(.${buttonClasses.disabled})`]: {
          color: palette?.ref?.primary?.['60'],
        },

        [`&:active:not(.${buttonClasses.disabled})`]: {
          color: palette?.ref?.primary?.['40'],
        },
      },
      [`&.${buttonClasses.disabled}`]: {
        color: buttonDisable.textColor,
        background: buttonDisable.bgColor,
        borderColor: buttonDisable.borderColor,
        pointerEvents: 'initial',
        cursor: 'not-allowed',
      },

      // '@media (min-width:1440px)': {
      //   '&.MuiButton-sizeMedium:not(.MuiButton-textSizeMedium)': {
      //     padding: '16px 48px',
      //     fontSize: '16px',
      //     lineHeight: '20px',
      //     borderRadius: '40px',
      //   },
      // },
      // '@media (min-width:768px) and (max-width: 1440px)': {
      //   '&.MuiButton-sizeMedium:not(.MuiButton-textSizeMedium)': {
      //     padding: '14px 48px',
      //     fontSize: '14px',
      //     borderRadius: '30px',
      //   },
      // },
      // padding: '10px',
      '.MuiLoadingButton-loadingIndicator': {
        color: palette?.ref?.gray['40'],
      },
    },
  },
} as Components['MuiButton']
