// export const BASE_URL = process.env.NEXT_PUBLIC_API_URL?.includes('http') ? process.env.NEXT_PUBLIC_API_URL : `${(window as any).origin}`
import { isDreoApp } from '@/utils'
import Cookies from 'js-cookie'

export const base_url = isDreoApp()
  ? process.env.NEXT_PUBLIC_APP_H5_API_URL
  : process.env.NEXT_PUBLIC_API_URL
// if (process.browser) {
//   base_url = process.env.NEXT_PUBLIC_API_URL?.includes('http')
//     ? process.env.NEXT_PUBLIC_API_URL
//     : `${(window as any).origin}${process.env.NEXT_PUBLIC_API_URL}`
// }

export const BASE_URL = ''

export const getBaseUrl = () =>
  isDreoApp()
    ? process.env.NEXT_PUBLIC_APP_H5_API_URL
    : Cookies.get('__location__') && Cookies.get('__location__') === 'eu'
    ? process.env.NEXT_PUBLIC_APP_H5_API_URL_EU
    : process.env.NEXT_PUBLIC_API_URL

/** 使用邮箱注册账户 */
export const USER_REGISTER_EMAIL = `${BASE_URL}/user/register/email`

/** 获取用户信息 */
export const USER_INFO = `${BASE_URL}/user/info`

/** 获取shopify access token */
export const SHOPIFY_ACCESS_TOKEN = `${BASE_URL}/transaction/customer/access/token`

/** 登录接口/获取无用户token状态下发起http需要的token */
export const OAUTH_LOGIN = `${BASE_URL}/oauth/login?timestamp=${new Date().getTime()}&lang=en-US`

/** 刷新token */
export const REFRESH_TOKEN = `${BASE_URL}/oauth/refresh-token?timestamp=${new Date().getTime()}&lang=en-US`

/** 退出登录 */
export const OAUTH_LOGOUT = `${BASE_URL}/oauth/logout?lang=en-US`

/** 发送邮件验证码 */
export const EMAIL_VERIFY = `${BASE_URL}/user/register/email/verify`

/** 激活邮箱用户 */
export const VERIFY_EMAIL_CODE = `${BASE_URL}/user/register/email/verify/code`

/** 发送重置密码邮件 */
export const PASSWORD_EMAIL_VERIFY = `${BASE_URL}/user/password/email/verify`

/** 修改密码 */
export const PASSWORD_UPDATE = `${BASE_URL}/user/password/update`

/** 重置密码 */
export const PASSWORD_RESET = `${BASE_URL}/user/password/email/verify/token`

/** 修改用户信息 */
export const UPDATE_USER_INFO = `${BASE_URL}/user/info`

/** 延保 */
export const PRODUCT_WARRANTY = `${BASE_URL}/support/product/warranty`

export const QUERY_IS_SUBSCRIBE = `${BASE_URL}/support/product/exist/subscribe`

/** 支持 */
export const SUPPORT_REQUEST = `${BASE_URL}/support/product/zendesk`

/** CES表单提交 */
export const SUBSCRIBE_CES = `${BASE_URL}/support/product/ces`

/** 订阅 */
export const SUBSCRIBE = `${BASE_URL}/support/product/subscribe`

/** 地址管理 */

// 新增/删除/修改
export const ADDRESS = `${BASE_URL}/transaction/customer/address`

// 地址列表
export const ADDRESS_LIST = `${BASE_URL}/transaction/customer/address/my/page`

/** 订单管理 */
// 订单列表
export const ORDER_LIST = `${BASE_URL}/transaction/order/page/self`

// 订单编号列表
export const ORDER_NUMBER_LIST = `${BASE_URL}/transaction/order/orderNumber/page`

// 订单编号查询订单ID和Key
export const GET_ORDER_INFO_BY_NUMBER = `${BASE_URL}/transaction/order/info/param`

// 未支付订单列表
export const ABANDONED_CHECKOUTS = `${BASE_URL}/transaction/order/checkouts`

// 获取购物车ID
export const GET_CART_ID = `${BASE_URL}/transaction/customer/cart`

// 获取物流信息
export const GET_TRACKING_INFO = `${BASE_URL}/transaction/tracking/info`

// 获取订单信息
export const GET_ORDER_INFO = `${BASE_URL}/transaction/order/info`

// 数据字典查询
export const SYS_DICT = `${BASE_URL}/common/sys/dict`

// 获取国家列表
export const COUNTRY_LIST = `${BASE_URL}/system/conf/countries`

// 获取产品订单评论
export const GET_PRODUCT_REVIEWS = `${BASE_URL}/transaction/comment/page`

// 横幅活动查询
export const CONF_ADVERTISE = `${BASE_URL}/common/conf/page`

// 产品model分类数据
export const PRODUCT_CATEGORY = `${BASE_URL}/common/conf/category/model`

// 推送至飞书机器人
export const SUPPORT_ROBOT = `/api/support/robot`
export const TRACKING_URL = `${BASE_URL}/transaction/pub/checkout/email/pay`

/** aws s3预签名上传接口 */
export const S3_UPLOAD_URL = `${BASE_URL}/file/presigned/upload/fb.img`

/** DISCOVER_CARD */
export const DISCOVER_CARD = `${BASE_URL}/h5/discover/`

/** strapi地址 */
export const STRAPI_BASE_URL = `${BASE_URL}/strapi/app`

/** strapi地址 */
export const STRAPI_BASE_URL_By_USER = `${BASE_URL}/strapi/user`

export const support = {
  getChannelList: `${BASE_URL}/after-sales/channels`,
  getCategoryList: `${BASE_URL}/after-sales/categorys`,
  getProductList: `${BASE_URL}/after-sales/product/category`,
  searchProductList: `${BASE_URL}/after-sales/product/search`,
  getProductByHandle: `${BASE_URL}/after-sales/product/seohandle`,
  getProductByModel: `${BASE_URL}/after-sales/product/model`,
  warrantyOnline: `${BASE_URL}/after-sales/warranty/online`,
  warrantyOffline: `${BASE_URL}/after-sales/warranty/offline`,
  warrantyOfflineRating: `${BASE_URL}/after-sales/warranty/offline/rating`,
}
