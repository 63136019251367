// export const oauthConfig = {
//   client_id: "6c2bfbdd497f4addbb77449edd3d73ec",
//   client_secret: "460df321de794ea08110cb3ed32c7452",
//   grant_type: "client_credentials",
//   scope: "all",
// }

// web
export const oauthConfig = {
  client_id: '6c2bfbdd497f4addbb77449edd3d73ec',
  client_secret: '460df321de794ea08110cb3ed32c7452',
  grant_type: 'client_credentials',
  scope: 'all',
}

// app
export const appOauthConfig = {
  client_id: '232f18f271904afa87b402aaa6a7cb44',
  client_secret: '0969689edf904b678217186450361c32',
  grant_type: 'client_credentials',
  scope: 'all',
}

export const USER_TOKEN_KEY = '__user_auth_token__'
export const SHOPIFY_ACCESS_TOKEN_KEY = '__user_shopify_access_token__'

export const serverUrl = process.env.SERVER_URL
