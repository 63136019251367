import { ThemeOptions } from '@mui/material/styles'

function hexToRgba(hex: string, opacity: number) {
  return (
    'rgba(' +
    parseInt('0x' + hex.slice(1, 3)) +
    ',' +
    parseInt('0x' + hex.slice(3, 5)) +
    ',' +
    parseInt('0x' + hex.slice(5, 7)) +
    ',' +
    opacity +
    ')'
  )
}

export const ref = {
  gray: {
    0: '#000000',
    10: '#131516',
    20: '#252525',
    30: '#444748',
    40: '#707070',
    50: '#7B7B7B',
    60: '#8C8C8C',
    70: '#BEBEBE',
    80: '#D7D7D7',
    90: '#E4E4E4',
    95: '#EFEFEF',
    97: '#F5F5F5',
    99: '#F9F9F9',
    100: '#FFFFFF',
  },
  primary: {
    0: '#000000',
    10: '#000D37',
    20: '#00195C',
    30: '#002982',
    40: '#023BA8',
    50: '#0051CF',
    60: '#2370DB',
    70: '#4A91E8',
    80: '#77B3F4',
    90: '#A3D3FF',
    95: '#E2F3F9',
    100: '#E6F4FF',
  },
  secondary: {
    0: '#000000',
    10: '#0E161A',
    20: '#17242B',
    30: '#22353E',
    40: '#2C4552',
    50: '#375767',
    60: '#43697C',
    70: '#6393AB',
    80: '#8FB2C3',
    90: '#CCDCE4',
    95: '#EEF4F6',
    99: '#FAFBFC',
    100: '#FFFFFF',
  },
  error: {
    0: '#000000',
    10: '#45030A',
    20: '#6B070E',
    30: '#911015',
    40: '#B8201E',
    50: '#DD3730',
    60: '#EC6259',
    70: '#F79186',
    80: '#FFBDB3',
    90: '#FFE1DB',
    95: '#FFF3EF',
    100: '#FFFFFF',
  },
  warning: {
    0: '#000000',
    10: '#663A00',
    20: '#8C5400',
    30: '#B37100',
    40: '#DA9103',
    50: '#FEB60D',
    60: '#FFCA37',
    70: '#FFDA62',
    80: '#FFE889',
    90: '#FFF2B3',
    95: '#FEFCE6',
    100: '#FFFFFF',
  },
  success: {
    0: '#000000',
    10: '#002104',
    20: '#004707',
    30: '#056E0B',
    40: '#0F9413',
    50: '#1BBA1B',
    60: '#42C73E',
    70: '#6BD463',
    80: '#96E08D',
    90: '#C2EDBB',
    95: '#EDFAEB',
    99: '#FCFCFC',
    100: '#FFFFFF',
  },
  info: {
    0: '#000000',
    10: '#001B41',
    20: '#002B68',
    30: '#003D93',
    40: '#1B5DBA',
    50: '#3078DD',
    60: '#4995FF',
    70: '#7AB1FF',
    80: '#A9CDFF',
    90: '#D4E6FF',
    95: '#E9F2FF',
    99: '#FCFCFC',
    100: '#FFFFFF',
  },
}

const grey = {
  '900': '#0D0D0D',
  '800': '#1A1A1A',
  '700': '#333333',
  '600': '#666666',
  '500': '#808080',
  '400': '#999999',
  '200': '#CCCCCC',
  '100': '#E5E5E5',
  // '50': '#F2F2F2',
  5: '#F2F2F2',
  10: '#E6E6E6',
  20: '#CCCCCC',
  40: '#999999',
  50: '#808080',
  60: '#666666',
  70: '#333333',
  80: '#333333',
  90: '#1A1A1A',
  95: '#0D0D0D',
}

const neutral = {
  main: ref.gray['10'],
  contrastText: '#FFFFFF',
}

export const primary = {
  main: ref.primary['50'],
  contrastText: '#FFFFFF',
}

const error = {
  main: ref.error['50'],
  contrastText: '#FFFFFF',
}

const info = {
  main: ref.info['50'],
  contrastText: '#FFFFFF',
}

const enviLight = {
  background: { main: ref.gray['100'] },
  onBackground: { main: ref.gray['10'] },
  neutral,
  onNeutral: { main: ref.gray['100'] },
  primary,
  onPrimary: { main: ref.primary['100'] },
  surface: { main: ref.gray['99'] },
  onSurface: { main: ref.gray['10'] },
  neutralContainer: { main: ref.gray['80'] },
  onNeutralContainer: { main: ref.gray['80'] },
  primaryContainer: { main: ref.primary['90'] },
  onPrimaryContainer: { main: ref.primary['10'] },
  outline: { main: ref.gray['50'] },
}

const enviDark = {
  background: { main: ref.gray['10'] },
  onBackground: { main: ref.gray['90'] },
  neutral: { main: ref.gray['80'] },
  onNeutral: { main: ref.gray['10'] },
  primary: { main: ref.primary['60'] },
  onPrimary: { main: ref.primary['10'] },
  surface: { main: ref.gray['10'] },
  onSurface: { main: ref.gray['90'] },
  neutralContainer: { main: '#25ADD0' },
  onNeutralContainer: { main: '#001F28' },
  primaryContainer: { main: '#004E60' },
  onPrimaryContainer: { main: '#B1EBFF' },
  outline: { main: '#8F9192' },
}

const text = {
  ultraStrong: { main: hexToRgba(enviLight.onSurface.main, 1) },
  strong: { main: hexToRgba(enviLight.onSurface.main, 0.88) },
  medium: { main: ref.gray['50'] },
  weak: { main: ref.gray['60'] },
  ultraWeak: { main: hexToRgba(enviLight.onSurface.main, 0.24) },
  link: { main: info.main },
  error: { main: error.main },
}

const colorThemeOptions: ThemeOptions = {
  palette: {
    ref,
    grey,
    neutral,
    border: {
      main: hexToRgba(ref.gray['80'], 0.5),
      secondary: hexToRgba(ref.gray['80'], 0.3),
    },
    black: {
      main: '#000',
      contrastText: '#FFFFFF',
    },
    white: {
      main: '#FFFFFF',
      contrastText: '#000000',
    },
    primary,
    secondary: {
      main: ref.secondary['60'],
      contrastText: '#FFFFFF',
    },
    error,
    warning: {
      main: ref.warning['50'],
      contrastText: '#FFFFFF',
    },
    success: {
      main: ref.success['50'],
      contrastText: '#FFFFFF',
    },
    info: {
      main: ref.info['50'],
      contrastText: '#FFFFFF',
    },
    enviLight,
    enviDark,
    // text,

    text: {
      primary: ref.gray[0],
    },
    text_ultraStrong: text.ultraStrong,
    text_strong: text.strong,
    text_medium: text.medium,
    text_weak: text.weak,
    text_ultraWeak: text.ultraWeak,
    text_link: text.link,
    text_error: text.error,

    enviLight_background: enviLight.background,
    enviLight_onBackground: enviLight.onBackground,
    enviLight_neutral: enviLight.neutral,
    enviLight_onNeutral: enviLight.onNeutral,
    enviLight_primary: enviLight.primary,
    enviLight_onPrimary: enviLight.onPrimary,
    enviLight_surface: enviLight.surface,
    enviLight_onSurface: enviLight.onSurface,
    enviLight_neutralContainer: enviLight.neutralContainer,
    enviLight_onNeutralContainer: enviLight.onNeutralContainer,
    enviLight_primaryContainer: enviLight.primaryContainer,
    enviLight_onPrimaryContainer: enviLight.onPrimaryContainer,
    enviLight_outline: enviLight.outline,

    surface1: {
      main: 'rgb(244,245,245)',
    },
    surface2: { main: 'rgb(235,236,236)' },
    surface3: { main: 'rgb(227,228,228)' },
    surface4: { main: 'rgb(220,222,222)' },
    surface5: { main: 'rgb(217,218,218)' },
  },
}

export default colorThemeOptions
