import { handleSaveAppToken, handleSaveToken } from '@/providers/auth-provider'
import { getAppInfo } from '@hsfe/dreojsapi'
import { isDreoApp } from './index'

/**
 * 从app端嵌入的页面中获取token
 */

function redirectToMultipass(
  token: string,
  return_to: string,
  isDreoApp = true
) {
  return (window.location.href = `/api/session?token=${token}${
    isDreoApp ? '&isDreoApp=true' : ''
  }&return_to=${encodeURIComponent(`${return_to}` || '/')}`)
}

if (typeof window !== 'undefined') {
  if (isDreoApp()) {
    // 是否验证过app登录态
    const hasAuthenticated = window.sessionStorage.getItem('hasAuthenticated')

    if (hasAuthenticated !== 'true') {
      getAppInfo({
        success: (data) => {
          // user 存入cookie
          if (data.appToken) {
            handleSaveToken(data.appToken, 1)
            handleSaveAppToken(data.appToken, 1)

            // shopify session
            // 跳转multipass
            if (!hasAuthenticated) {
              window.sessionStorage.setItem('hasAuthenticated', 'true')
              redirectToMultipass(data.appToken, window.location.href)
            }
          }
        },
      })
    } else {
      window.sessionStorage.setItem('hasAuthenticated', '')
    }
  }
}
