import Base64 from 'crypto-js/enc-base64'
import { stringify } from 'crypto-js/enc-utf8'
import { Mask } from 'react-text-mask'

export { useCurrentMediaQuery } from '@/hooks/useCurrentMediaQuery'

export const isObject = (value: unknown): value is Record<any, any> =>
  value !== null && typeof value === 'object'
// eslint-disable-next-line @typescript-eslint/ban-types
export const isFunction = (value: unknown): value is Function =>
  typeof value === 'function'

export const isString = (value: unknown): value is string =>
  typeof value === 'string'
export const isBoolean = (value: unknown): value is boolean =>
  typeof value === 'boolean'
export const isNumber = (value: unknown): value is number =>
  typeof value === 'number'
export const isUndef = (value: unknown): value is undefined =>
  typeof value === 'undefined'

export const isDev =
  process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test'

/**
 * 传入一个对象，和键集合，返回对应的对象中的键值对
 * @param obj
 * @param keys
 */
export const subset = <
  O extends { [key in string]: unknown },
  K extends keyof O
>(
  obj: O,
  keys: K[]
) => {
  const filteredEntries = Object.entries(obj).filter(([key]) =>
    keys.includes(key as K)
  )
  return Object.fromEntries(filteredEntries) as Pick<O, K>
}

export function uuid() {
  function S4() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
  }
  return (
    S4() +
    S4() +
    '-' +
    S4() +
    '-' +
    S4() +
    '-' +
    S4() +
    '-' +
    S4() +
    S4() +
    S4()
  )
}

export function hexToRgba(hex: string, opacity: number) {
  let c: any = ''
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('')
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]]
    }
    c = '0x' + c.join('')
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(
      ','
    )}, ${opacity})`
  }
  throw new Error('Bad Hex')
}

/**
 * 获取base64解码后得到的变体ID
 * @param variantIdStr 传入的base64变体ID
 * @returns variantId
 */
export const decryptVariantId = (variantIdStr: string | undefined) => {
  if (!variantIdStr) return ''
  return stringify(Base64.parse(variantIdStr)).split('ProductVariant/')[1] ?? ''
}

// eslint-disable-next-line no-useless-escape
export const passwordReg =
  // eslint-disable-next-line no-useless-escape
  /[0-9a-zA-Z$@$!%*#?&,./|\[\]\-+_=\(\)~<>《》¥{}`、;:"'「」£¿€…]/
export const filterPasswordCharacter = (value: string) => {
  let newValue = ''
  for (const char of value) {
    if (passwordReg.test(char)) {
      newValue += char
    }
  }
  // return value.split('').filter(char => passwordReg.test(char)).join('')
  return newValue
}

export const isMobile = () => {
  const userAgent = navigator.userAgent
  return !!userAgent.match(
    /(iPhone|iPod|Android|ios|iPad|AppleWebKit.*Mobile.*)/i
  )
}

/**
 *
 * @param ctx CanvasRenderingContext2D
 * @param img Image Element
 * @param width
 * @param height
 * @param offsetX
 * @param offsetY
 */
export const drawImageProp = (
  ctx: CanvasRenderingContext2D,
  img: HTMLImageElement,
  width?: number,
  height?: number,
  offsetX?: number,
  offsetY?: number
) => {
  const containerWidth = width ?? ctx.canvas.offsetWidth
  const containerHeight = height ?? ctx.canvas.offsetWidth
  const contentWidth = img.width
  const contentHeight = img.height

  const contentRatio = contentWidth / contentHeight
  const containerRatio = containerHeight / containerHeight
  let resultHeight = 0
  let resultWidth = 0
  offsetX = typeof offsetX === 'undefined' ? 0.5 : offsetX
  offsetY = typeof offsetY === 'undefined' ? 0.5 : offsetY

  if (contentRatio > containerRatio) {
    resultHeight = containerHeight
    resultWidth = containerHeight * contentRatio
  } else {
    resultWidth = containerWidth
    resultHeight = containerWidth / contentRatio
  }

  const offsetLeft = (containerWidth - resultWidth) * offsetX
  const offsetTop = (containerHeight - resultHeight) * offsetY

  ctx.drawImage(img, offsetLeft, offsetTop, resultWidth, resultHeight)
}

export const isDreoApp = () => {
  return (
    typeof window !== 'undefined' &&
    window.hsJSBridge &&
    window.hsJSBridge._isDreoApp
  )
}

// 订单号提示
export const orderNumberPlaceholderMap: { [K in string]: string } = {
  'buyway.web': 'DREOXXXX',
  'buyway.amazon': 'XXX-XXXXXXX-XXXXXXX',
  'buyway.walmart': '#XXXXXXX-XXXXXX',
  'buyway.kickstarter': 'Backer ID',
  'buyway.other': 'CSXXXXXXXX',
}

export const orderNumberMask: { [K in string]: Mask } = {
  'buyway.web': ['D', 'R', 'E', 'O', /\d/, /\d/, /\d/, /\d/],
  'buyway.other': ['C', 'S', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
  'buyway.amazon': [
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
  'buyway.walmart': [
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
  'buyway.kickstarter': [
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
  default: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
}

export const orderNumberMaskMap: { [K in string]: Mask } = {
  // 'Dreo Official Website': ['D', 'R', 'E', 'O', /\d/, /\d/, /\d/, /\d/, /\d/],
  Amazon: [
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
  'Walmart.com': [
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
  default: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
}

// 订单号提示
export const warrantyOrderNumberPlaceholderMap: { [K in string]: string } = {
  'Dreo Official Website': 'DREOXXXXX or XXX-XXXX-XXXXXX',
  Amazon: 'XXX-XXXXXXX-XXXXXXX or XXX-XXXX-XXXXXX',
  'Walmart.com': 'XXXXXXXXXXXXX',
}
