declare global {
  interface Window {
    fbq: any
  }
}

export interface Options {
  autoConfig: boolean
  debug: boolean
}

export interface AdvancedMatching {
  ct: string
  country: string
  db: string
  em: string
  fn: string
  ge: string
  ln: string
  ph: string
  st: string
  zp: string
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Data {}

export interface AddPaymentInfo extends Data {
  content_category?: string
  content_ids?: object[] | object
  contents?: object[]
  currency?: string
  value?: number
}

/**
 * content_type required and at least one of content_ids or contents required
 * this version is compatible with dynamic ads
 */
export interface DynamicAdAddToCart extends Data {
  content_ids?: object[] | object
  content_name?: string
  content_type: string
  contents?: object[]
  currency?: string
  value?: number
}

/**
 * this version is not compatible with dynamic ads
 */
export interface AddToCart extends Data {
  content_ids?: object[] | object
  content_name?: string
  content_type?: string
  contents?: object[]
  currency?: string
  value?: number
}

export interface AddToWishlist extends Data {
  content_name?: string
  content_category?: string
  content_ids?: object[] | object
  contents?: object[]
  currency?: string
  value?: number
}

export interface AddToWishlist extends Data {
  content_name?: string
  content_category?: string
  content_ids?: object[] | object
  contents?: object[]
  currency?: string
  value?: number
}

export interface CompleteRegistration extends Data {
  content_name?: string
  currency?: string
  status?: boolean
  value?: number
}

export interface InitiateCheckout extends Data {
  content_category?: string
  content_ids?: object[] | object
  contents?: object[]
  currency?: string
  num_items?: number
  value?: number
  content_type?: string
}

export interface Lead extends Data {
  content_category?: string
  content_name?: string
  currency?: string
  value?: number
}

/**
 * content_type required and at least one of content_ids or contents required
 * this version is compatible with dynamic ads
 */
export interface DynamicAdPurchase extends Data {
  content_ids?: object[] | object
  content_name?: string
  content_type: string
  contents?: object[]
  currency: string
  num_items?: number
  value: number
}

/**
 * this version is not compatible with dynamic ads
 */
export interface Purchase extends Data {
  content_ids?: object[] | object
  content_name?: string
  content_type?: string
  contents?: object[]
  currency: string
  num_items?: number
  value: number
}

export interface Search extends Data {
  content_category?: string
  content_ids?: object[] | object
  contents?: object[]
  currency?: string
  search_string?: string
  value?: number
}

export interface StartTrial extends Data {
  currency?: string
  predicted_ltv?: number
  value: number
}

export interface Subscribe extends Data {
  currency?: string
  predicted_ltv?: number
  value: number
}

/**
 * content_type required and at least one of content_ids or contents required
 * this version is compatible with dynamic ads
 */
export interface DynamicAdViewContent extends Data {
  content_ids?: string[] | string
  content_name?: string
  content_type: string
  contents?: object[]
  currency?: string
  value?: number
}

/**
 * this version is not compatible with dynamic ads
 */
export interface ViewContent extends Data {
  content_ids?: string[] | string
  content_name?: string
  content_type?: string
  content_category?: string
  contents?: object[]
  currency?: string
  value?: number
}

export interface RemoveFromCart extends Data {
  content_ids?: string[] | string
  contents?: object[]
}

const initialized = true
const debug = process.env.NODE_ENV !== 'production'

/**
 * Utilities
 */

/**
 * Warn
 * @param  {...any} args
 */
const warn = (...args: any) => {
  if (!debug) {
    return
  }
  // eslint-disable-next-line no-console
  console.info(...['[react-facebook-pixel]'].concat(args))
}

/**
 * Log
 * @param  {...any} args
 */
const log = (...args: any) => {
  if (!debug) {
    return
  }
  // eslint-disable-next-line no-console
  console.info(...['[react-facebook-pixel]'].concat(args))
}

const verifyInit = () => {
  if (!initialized) {
    warn(
      'Pixel not initialized before using call ReactPixel.init with required params'
    )
  }
  return initialized
}

//
export default {
  FB_PIXEL_ID: `${process.env.NEXT_PUBLIC_FB_PIXEL_ID}`,

  pageView() {
    if (!verifyInit()) {
      return
    }

    window.fbq('track', 'PageView') // eslint-disable-line no-undef

    if (debug) {
      log("called fbq('track', 'PageView');")
    }
  },

  purchase(data: Purchase) {
    if (!verifyInit()) {
      return
    }

    window.fbq('track', 'Purchase', data)

    if (debug) {
      log(`called fbq('track', 'Purchase');`)

      if (data) {
        log('with data', data)
      }
    }
  },

  viewContent(data: ViewContent) {
    if (!verifyInit()) {
      return
    }

    window.fbq('track', 'ViewContent', data)

    if (debug) {
      log(`called fbq('track', 'ViewContent');`)

      if (data) {
        log('with data', data)
      }
    }
  },

  addToCart(data: AddToCart) {
    if (!verifyInit()) {
      return
    }

    window.fbq('track', 'AddToCart', data)

    if (debug) {
      log(`called fbq('track', 'AddToCart');`)

      if (data) {
        log('with data', data)
      }
    }
  },

  subscribe(data: Subscribe) {
    if (!verifyInit()) {
      return
    }

    window.fbq('track', 'Subscribe', data)

    if (debug) {
      log(`called fbq('track', 'Subscribe');`)

      if (data) {
        log('with data', data)
      }
    }
  },

  completeRegistration(data: CompleteRegistration) {
    if (!verifyInit()) {
      return
    }

    window.fbq('track', 'CompleteRegistration', data)

    if (debug) {
      log(`called fbq('track', 'CompleteRegistration');`)

      if (data) {
        log('with data', data)
      }
    }
  },

  initiateCheckout(data: InitiateCheckout) {
    if (!verifyInit()) {
      return
    }

    window.fbq('track', 'InitiateCheckout', data)

    if (debug) {
      log(`called fbq('track', 'InitiateCheckout');`)

      if (data) {
        log('with data', data)
      }
    }
  },

  removeFromCart(data: RemoveFromCart) {
    if (!verifyInit()) {
      return
    }

    window.fbq('track', 'RemoveFromCart', data)

    if (debug) {
      log(`called fbq('track', 'RemoveFromCart');`)

      if (data) {
        log('with data', data)
      }
    }
  },

  track(title: string, data?: Data | any) {
    if (!verifyInit()) {
      return
    }

    window.fbq('track', title, data)

    if (debug) {
      log(`called fbq('track', '${title}');`)

      if (data) {
        log('with data', data)
      }
    }
  },

  trackSingle(pixel: string, title: string, data?: Data | any) {
    if (!verifyInit()) {
      return
    }

    window.fbq('trackSingle', pixel, title, data) // eslint-disable-line no-undef

    if (debug) {
      log(`called fbq('trackSingle', '${pixel}', '${title}');`)

      if (data) {
        log('with data', data)
      }
    }
  },

  trackCustom(event: string, data?: Data | any) {
    if (!verifyInit()) {
      return
    }

    window.fbq('trackCustom', event, data) // eslint-disable-line no-undef

    if (debug) {
      log(`called fbq('trackCustom', '${event}');`)

      if (data) {
        log('with data', data)
      }
    }
  },

  trackSingleCustom(pixel: string, event: string, data?: Data | any) {
    if (!verifyInit()) {
      return
    }

    window.fbq('trackSingle', pixel, event, data) // eslint-disable-line no-undef

    if (debug) {
      log(`called fbq('trackSingleCustom', '${pixel}', '${event}');`)

      if (data) {
        log('with data', data)
      }
    }
  },

  grantConsent() {
    if (!verifyInit()) {
      return
    }

    window.fbq('consent', 'grant')

    if (debug) {
      log(`called fbq('consent', 'grant');`)
    }
  },

  revokeConsent() {
    if (!verifyInit()) {
      return
    }

    window.fbq('consent', 'revoke')

    if (debug) {
      log(`called fbq('consent', 'revoke');`)
    }
  },

  fbq(...args: Array<unknown>) {
    if (!verifyInit()) {
      return
    }

    window.fbq(...args) // eslint-disable-line no-undef

    if (debug) {
      log(`called fbq('${args.slice(0, 2).join("', '")}')`)

      if (args[2]) {
        log('with data', args[2])
      }
    }
  },
}
